export enum EvidenceType {
  JDID = 'JDID', // justificatif d'identité
  JBAN = 'JBAN', // identité bancaire
  JDDO = 'JDDO', // justificatif de domicile
  JDRE = 'JDRE', // justificatif de revenus
  JDOP = 'JDOP', // bon de commande
  JDDS = 'JDDS', // domicile secondaire
}

export enum EvidenceSubType {
  CARTE_IDENTITE_NATIONALE = 'CARTE_IDENTITE_NATIONALE',
  PASSEPORT = 'PASSEPORT',
  RIBS = 'RIBS',
  RIBA = 'RIBA',
  TITRE_DE_SEJOUR = 'TITRE_DE_SEJOUR',
}

export enum ValidityCd {
  O = 'O',
  F = 'F',
  N = 'N',
  C = 'C',
}

export enum StatusCd {
  OK = 'OK',
  TIMEOUT = 'TIMEOUT',
  SERVER_ERROR = 'SERVER_ERROR',
  ANALYZE_KO = 'ANALYZE_KO',
  INCONSISTENCY_IDENTITY = 'INCONSISTENCY_IDENTITY',
  EXPIRED_EVIDENCE = 'EXPIRED_EVIDENCE',
  OUTDATED_EVIDENCE = 'OUTDATED_EVIDENCE',
  UNREADABLE = 'UNREADABLE',
  INCONSISTENCY_TYPE = 'INCONSISTENCY_TYPE',
  ANALYSE_OCR_KO = 'ANALYSE_OCR_KO',
  INITIALISATION_OCR_KO = 'INITIALISATION_OCR_KO',
  LECTURE_COS_KO = 'LECTURE_COS_KO',
  SUPPRESSION_COS_KO = 'SUPPRESSION_COS_KO',
  TYPE_PIECE_INVALIDE = 'TYPE_PIECE_INVALIDE',
  SOUSTYPE_PIECE_INVALIDE = 'SOUSTYPE_PIECE_INVALIDE',
  TYPE_EMPRUNTEUR_INVALIDE = 'TYPE_EMPRUNTEUR_INVALIDE',
  BANK_INFORMATIONS_API_CONTROLE_BIC_IBAN = 'BANK_INFORMATIONS_API_CONTROLE_BIC_IBAN',
  CONTROLE_SEPA_KO = 'CONTROLE_SEPA_KO',
  CONTROLE_BIC_KO = 'CONTROLE_BIC_KO',
  CONTROLE_IBAN_KO = 'CONTROLE_IBAN_KO',
  ABORTED = 'AbortError',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
}

export enum UploadType {
  SINGLE = 'single',
  DOUBLE = 'double',
  MULTIPLE = 'multiple',
}

export interface IEvidenceType {
  id: string;
  label?: string;
  uploadType: UploadType; // 'double' | 'single' | 'multiple'
}

export interface IEvidenceBlock {
  index: number;
  mandatory: boolean;
  name: string;
  hideUnderThreshold: boolean;
  evidenceTypes: IEvidenceType[];
  maxMultipleFiles?: number;
}

export interface IBlock {
  title: string;
  description: string;
  maxPdfSize?: number;
  evidenceBlocks: {
    [key: string]: IEvidenceBlock;
  };
}
export interface IParametresOcr {
  parametres: IOcr;
}

export interface IOcr {
  hasFeatureOcrCoborrower: boolean;
  overdraftThreshold: number;
  overdraftThresholdBDC: number;
  maxFileSize: number;
  maxPdfSize: number;
  maxReloadFile: number;
  blocks: {
    [key: string]: IBlock;
  };
  availableToRiba: boolean;
  maxFilePages: number;
  maxFilePagesBDCO: number;
}

export enum EIsExpected {
  OBLIGATOIRE = 'Obligatoire',
  FACULTATIF = 'Facultatif',
  NON_ATTENDU = 'Non Attendu',
}
