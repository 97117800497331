import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit';
import { FetchArgs, fetchBaseQuery, retry } from '@reduxjs/toolkit/dist/query';
import { RootState } from '@store';
import {
  getAuthenticationEnabled,
  getAuthenticationPath,
  getMsBffBaseURL,
  getMsBffDomainPrefix,
  isAuthenticationRequired,
  setAuthenticationRequired,
} from '@utils';
import { setError } from 'store/error/errorSlice';
import dayjs from 'dayjs';
import { IServerError } from '@types';

const ERREUR_INCONNUE = 'ERREUR_INCONNUE';
const MAX_ATTEMPTS = 5;
const GLOBAL_TIMEOUT = 45000;

export enum IEndPoints {
  souscription = 'souscription',
  simulation = 'simulation',
  authgw = 'authGw',
}

export const rtkQueryUtils = (
  endpointsToNotCheck: ((endPointName: string, errorCode?: string) => boolean)[]
): {
  rtkQueryErrorLogger: Middleware;
} => {
  const rtkQueryErrorLogger: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
      const endPointName = action?.meta?.arg?.endpointName ?? '';
      if (action?.payload?.status === 404) {
        return next(action);
      }
      if (endPointName === 'sendLogs') {
        if (action?.meta?.requestStatus === 'fulfilled') {
          // @ts-ignore
          console.sendLogsFailed = 0;
        } else {
          // @ts-ignore
          console.sendLogsFailed = (console.sendLogsFailed ?? 0) + 1;
        }
      }

      const serverResponseError = getServerResponseError(action?.payload);
      const hasError = !!serverResponseError;

      // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
      let needSendError = !!isRejectedWithValue(action) || hasError;
      // Recherche sur les différentes API (souscription, simulation ...) si ce endpoint doit ne pas lever la popin d'erreur
      if (needSendError) {
        for (const endpointIndex in endpointsToNotCheck) {
          if (
            !endpointsToNotCheck[endpointIndex](
              endPointName,
              serverResponseError?.error
            )
          ) {
            needSendError = false;
            break;
          }
        }
      }

      if (needSendError) {
        let responseError;

        const date = dayjs(new Date()).format('DD/MM/YYYY HH:mm:ss.SSS');

        // Cas du service qui n'est pas joignable
        if (action?.payload?.status === 'FETCH_ERROR') {
          responseError = {
            code: JSON.stringify(action?.payload?.status) ?? ERREUR_INCONNUE,
            service: action?.meta?.arg?.endpointName,
            timestamp: date,
          };
        } else {
          // Cas d'erreur retour webservice (400, 404, 500, etc.)
          if (action?.payload?.status) {
            responseError = {
              description: (action?.payload?.data ?? [])[0]?.error_description,
              code: serverResponseError?.error ?? ERREUR_INCONNUE,
              status: action?.payload?.status,
              service: action?.meta?.arg?.endpointName,
              timestamp:
                serverResponseError?.additional_information?.timestamp ?? date,
              invalidData:
                serverResponseError?.additional_information?.invalid_data,
            };
          } else {
            responseError = {
              code: ERREUR_INCONNUE,
              timestamp: date,
            };
          }
        }
        action.payload.status = 500;

        api.dispatch(setError(responseError));
      }
      return next(action);
    };
  return {
    rtkQueryErrorLogger,
  };
};

export const getDynamicQueriesWithRetries = (msEndPoint?: IEndPoints) =>
  retry(
    async (args: string | FetchArgs, api, extraOptions) => {
      const startTime = Date.now();

      const result = await fetchBaseQuery({
        baseUrl: getBffBaseUrlQueryUtils(msEndPoint),
        // If we have a requestId set in state, pass it in request header
        prepareHeaders: (headers, { getState }) => {
          const requestId = (getState() as RootState)?.configuration?.state
            ?.requestId;
          headers.set('requestId-SOAH', requestId ?? 'unknown');
          if ((requestId ?? '').length > 0) {
            headers.set('requestId', requestId ?? '');
          }
          return headers;
        },
      })(args, api, extraOptions);

      const duration = Date.now() - startTime;

      // Check if redirect to login page is needed
      if (
        getAuthenticationEnabled() &&
        (result.meta?.response?.status === 401 ||
          result.meta?.response?.status === 405)
      ) {
        setAuthenticationRequired();
        // Ce n'est pas une erreur car redirigé vers ping
        result.error = undefined;
        const entiteFinanciere = (
          sessionStorage.getItem('entite-financiere') ?? ''
        ).replaceAll('"', '');
        const urlRedirectLogin =
          getBffBaseUrl()
            .replace('/souscription', '')
            .replace('/simulation', '') +
          getAuthenticationPath() +
          ((entiteFinanciere ?? '').length > 0
            ? '?finEnt=' + entiteFinanciere
            : '');
        window.location.assign(urlRedirectLogin ?? '');
      }

      let overrideResult = { ...result } as {
        data: IServerError[];
        error: any;
      };
      const hasError =
        (overrideResult?.data ?? [])[0]?.error &&
        (overrideResult?.data ?? [])[0]?.error_description;
      if (hasError) {
        overrideResult = {
          ...overrideResult,
          error: {
            ...overrideResult?.error,
            data: result?.data ?? [],
            status: 500,
          },
        };
      }

      if (overrideResult?.error) {
        return {
          ...overrideResult,
          error: overrideResult.error && { ...overrideResult.error, duration },
        };
      }
      return result;
    },
    {
      retryCondition(
        error: any, // FetchBaseQueryError & { duration: number }
        args,
        { attempt }
      ) {
        return (
          attempt < MAX_ATTEMPTS &&
          !/^[45][0-9]{2}$/.test(error?.status + '') &&
          (error?.duration * (attempt + 1) < GLOBAL_TIMEOUT ||
            args?.url?.includes('switch/consult')) &&
          !isAuthenticationRequired()
        );
      },
    }
  );

export const getCommonPartDomain = (domainOrign = window.location.hostname) => {
  // Env INT et supérieur
  if (getMsBffDomainPrefix()) {
    return domainOrign.replace(/^(spa\.)*(.*)$/, '$2');
  }

  // Environnement de dev
  if (getMsBffBaseURL()) {
    return domainOrign.replace(/^([^.]+\.)(.*)$/, '$2');
  }
  return '';
};

// Retourne une erreur s'il y a une erreur, null sinon
const getServerResponseError = (payload: any): IServerError | null => {
  // On cherche une erreur dans le payload
  const withHttpError = (payload?.data ?? [])[0];
  if (withHttpError?.error) {
    return withHttpError as IServerError;
  }
  const withHttpSuccess = (payload ?? [])[0];
  if (withHttpSuccess?.error && withHttpSuccess?.error_description) {
    return withHttpSuccess as IServerError;
  }
  return null;
};

const getBffBaseUrlQueryUtils = (msEndPoint?: IEndPoints) => {
  // Calculé dynamiquement (à partir de l'environnement d'intégration)
  if (getMsBffDomainPrefix()) {
    return (
      window.location.protocol +
      '//' +
      getMsBffDomainPrefix() +
      '.' +
      getCommonPartDomain() +
      (msEndPoint && msEndPoint !== IEndPoints.authgw
        ? '/' + msEndPoint + '/' + (process.env.REACT_APP_API_PATHNAME ?? '')
        : '')
    );
  }

  // A partir de la config msBffBaseURL (URL non dynamiquement calculable -> env dev par exemple)
  const bffBaseURL = getMsBffBaseURL();
  if (bffBaseURL) {
    const withEndPoint =
      msEndPoint && msEndPoint !== IEndPoints.authgw
        ? msEndPoint + '/' + (process.env.REACT_APP_API_PATHNAME ?? '')
        : '';
    return (bffBaseURL ?? process.env.REACT_APP_API_HOST) + withEndPoint;
  }

  // A partir du fichier d'environnement (local)
  const fromEnvFile =
    !msEndPoint || msEndPoint === IEndPoints.souscription
      ? process.env.REACT_APP_API_HOST
      : msEndPoint === IEndPoints.simulation
      ? process.env.REACT_APP_API_SIMU_HOST
      : process.env.REACT_APP_API_AUTHGW_HOST;

  return (
    fromEnvFile +
    (msEndPoint && msEndPoint !== IEndPoints.authgw
      ? process.env.REACT_APP_API_PATHNAME ?? ''
      : '')
  );
};

export const getBffBaseUrl = getBffBaseUrlQueryUtils;
